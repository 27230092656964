import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Avatar from '@mui/material/Avatar';

import BasicInfo from './components/BasicInfo';
import Contact from './components/Contact';

import { ReactComponent as UploadIcon } from '../../assets/Icons/upload.svg'
import { ReactComponent as BuildingIcon } from '../../assets/Icons/building.svg'
import { LOADING, SUCCESS } from '../../constants/common';
import { useGeneralProfile } from '../../contexts/Organization';
import AboutMe from '../../components/BasicProfile/AboutMe';
import { useAlert } from '../../contexts/Alert';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, useWatch } from 'react-hook-form';
import { imageTypes } from '../../constants/lists';
import { profileImageValidationSchema } from '../../validations/profileValidations';

const ProfileOrganization = () => {
  const { facilityInfo, fetchFacilityInfo, editFacilityPhoto, updatePhotoStatus, updateGeneralProfileState } = useGeneralProfile();
  const [profileImageURL, setProfileImageURL] = useState(null);
  const [imageError, setImageError] = useState(null);
  const { sendAlert } = useAlert();

  const {
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: zodResolver(profileImageValidationSchema),
    mode: "onChange",
    defaultValues: {
      main_image: facilityInfo.main_image || null,
    },
  });


  const formValues = useWatch({ control });

  useEffect(() => {
    setProfileImageURL(formValues.main_image);
  }, [formValues]);

  useEffect(() => {
    setImageError(errors.main_image?.message)
  }, [errors.main_image]?.message);

  useEffect(() => {
    if (facilityInfo.main_image) {
      handleFileRead(facilityInfo.main_image);
    }
  }, [facilityInfo.main_image]);

  const handleFileRead = (file) => {
    if (!file || !file.type?.startsWith('image/')) {
      setProfileImageURL(null);
      return;
    }
    const reader = new FileReader();
    reader.onload = (event) => {
      setProfileImageURL(event.target.result);
    };
    reader.onerror = () => {
      console.error('Error reading the file.');
      sendAlert("There is an issue while reading this file", "error")
    };
    reader.readAsDataURL(file);
  };

  function handleFileInput(event) {
    const file = event.target.files?.[0];

    if (!file) {
      console.log('here')
      setProfileImageURL(null);
      setValue('main_image', null);
      return;
    }

    if (file.size > 5 * 1024 * 1024) { // 5 MB
      console.log('here1')
      errors['main_image'] = { message: 'The logo must be less than 5 MB.' };
      setProfileImageURL(null);
      setValue('main_image', null);
      return;
    }

    if (!imageTypes.includes(file.type)) { // 5 MB
      console.log('here2')
      // sendAlert('The logo must be less than 5 MB.', 'error');
      errors['main_image'] = { message: 'Please upload a valid image file.\n(jpeg, jpg, png, svg+xml)' };
      setProfileImageURL(null);
      setValue('main_image', null);
      return;
    }

    if (!file.type.startsWith('image/')) {
      console.log('here3')
      // sendAlert('Please select a valid image file.', 'error');
      setProfileImageURL(null);
      setValue('main_image', null);
      errors['main_image'] = { message: 'Please select a valid image file.' };
      return;
    }
    const img = new Image();
    let imageLoadedSuccessfully = false;
    img.src = URL.createObjectURL(file);

    img.onload = () => {
      imageLoadedSuccessfully = true;
      const width = img.width;
      const height = img.height;

      if (width < 300 || height < 300) {
      console.log('here4')
        errors['main_image'] = { message: 'The image dimensions should be at least 300x300 pixels.' };
        setProfileImageURL(null);
        setValue('main_image', null);
        return;
      }

      if (width > 2000 || height > 2000) {
      console.log('here5')
        errors['main_image'] = { message: 'The image dimensions should not exceed 2000x2000 pixels.' };
        setProfileImageURL(null);
        setValue('main_image', null);
        return;
      }
      setValue('main_image', file);
      errors['main_image'] = {};
      setImageError('');
      console.log(profileImageURL)
      if (profileImageURL) {
        editFacilityPhoto(file);
      }
      handleFileRead(file);
    }
    img.onerror = () => {
      errors['main_image'] = { message: 'There was an error loading the image. Please select a valid image file.' };
      setProfileImageURL({});
      setValue('main_image', {});
      return;
    };
  }

  useEffect(() => {
    if (updatePhotoStatus === SUCCESS) {
      updateGeneralProfileState('updatePhotoStatus', '')
      fetchFacilityInfo()
    }
  }, [updatePhotoStatus])

  return (
    <Paper
      sx={{
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        gap: 3
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: 'border.dark'
          }}
        >
          <BuildingIcon width={24} height={24} />
        </Box>
        <Typography variant='h4' ml={1}>Organization Settings</Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <BasicInfo />
        </Grid>
        <Grid item xs={4}>
          <Box
            sx={{
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: 'border.main',
              p: 3,
              borderRadius: 2
            }}
          >
            <Typography variant='h5' mb={3}>Upload logo</Typography>
            {updatePhotoStatus === LOADING ? (
              <Box
                sx={{
                  border: '1px dashed',
                  borderColor: 'border.dark',
                  borderRadius: 1.5,
                  height: 145,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  position: 'relative',
                  '&:hover': {
                    backgroundColor: 'primary.light'
                  }
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Box
                sx={{
                  border: '1px dashed',
                  borderColor: 'border.dark',
                  borderRadius: 1.5,
                  height: 142,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  position: 'relative',
                  '&:hover': {
                    backgroundColor: 'primary.light'
                  }
                }}
                component='label'
              >
                {profileImageURL ? (
                  <Avatar
                    sx={{ width: '100%', height: 'auto', borderRadius: 0 }}
                    src={profileImageURL}
                  />
                ) : (
                  <>
                    <UploadIcon />
                    <Typography variant='body2' color='text.primary'>Upload Logo</Typography>
                    <Box
                      component='input'
                      sx={{
                        display: 'none'
                      }}
                      type='file'
                      accept='image/*'
                      onChange={handleFileInput}
                    />
                  </>
                )}
                <input
                  type='file'
                  accept='image/*'
                  onChange={handleFileInput}
                  style={{ display: 'none' }}
                  name='main_image'
                  error={!!errors.main_image}
                  helperText={errors.main_image?.message} />
                {!!errors.main_image &&
                  <Typography variant="caption" color="error" sx={{ textAlign: 'center' }}>
                    {errors.main_image?.message}
                  </Typography>
                }
              </Box>
            )}
            <Box
              component='ul'
              sx={{
                mx: 0,
                my: 3,
                pl: 2
              }}
            >
              <Typography variant='caption' color='text.secondary' component='li' lineHeight='150%'>Use a solid background color.</Typography>
              <Typography variant='caption' color='text.secondary' component='li' lineHeight='150%'>Use a graphical logo or image rather than text.</Typography>
              <Typography variant='caption' color='text.secondary' component='li' lineHeight='150%'>Leave some space around your icon.</Typography>
              <Typography variant='caption' color='text.secondary' component='li' lineHeight='150%'>Upload an image that is 265 px square or larger.</Typography>
            </Box>
            <Box
              component='label'
            >
              <Box
                component='input'
                sx={{
                  display: 'none'
                }}
                type='file'
                accept='image/*'
                onChange={handleFileInput}
              />
              <Button
                fullWidth
                sx={{
                  backgroundColor: 'primary.light'
                }}
                component='span'
              >
                Upload
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Contact />
      <AboutMe />
    </Paper>
  )
}

export default ProfileOrganization;