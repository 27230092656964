import { FormControl, InputLabel, MenuItem, Select, Typography, SelectChangeEvent } from "@mui/material";
import React, { useEffect } from "react";
import { Controller } from "react-hook-form";

const CustomMultiSelectInput = ({
  name,
  value = [],
  onChange,
  error,
  label,
  labelId,
  helperText,
  control,
  items,
  trigger,
  multiple = false,
  ...rest
}) => {

  const handleInput = (e) => {
    const input = e.target.value;
    onChange({ [name]: input });
    trigger && trigger(name);
  };

  const renderDisabledMenuItem = () => (
    <MenuItem
      sx={{
        p: 0
      }}
      value=""
      disabled
    >
      Select {label || "item"}
    </MenuItem>
  );

  const renderMenuItems = (item) => (
    <MenuItem
      key={item?.key}
      value={item?.value || ""}
      sx={{
        flexDirection: 'column',
        alignItems: 'flex-start',
        py: 1
      }}
    >
      <Typography variant='body2'>{item?.key}</Typography>
      <Typography variant='caption' color='text.secondary'>{item?.data}</Typography>
    </MenuItem>
  );

  const renderSelectField = (fieldProps) => (
    <>
      <Select
        {...rest}
        {...fieldProps}
        multiple={multiple}
        value={fieldProps.value || (multiple ? [] : "")}
        label={label}
        name={name}
        onChange={handleInput}
        error={!!error}
      >
        {renderDisabledMenuItem()}
        {items?.map(item => renderMenuItems(item)) || null}
      </Select>
      {!!error && (
        <Typography color="error" variant="body2" sx={{ pl: 1, pt: 0.5 }}>
          {error.message || ""}
        </Typography>
      )}
    </>
  );

  return control ? (
    <FormControl fullWidth error={!!error}>
      <InputLabel id={labelId || ""}>{label || ""}</InputLabel>
      <Controller
        name={name}
        control={control}
        render={({ field }) => renderSelectField(field)}
      />
    </FormControl>
  ) : (
    <FormControl fullWidth>
      <InputLabel id={labelId || ""}>{label || ""}</InputLabel>
      {renderSelectField()}
    </FormControl>
  );
};

export default CustomMultiSelectInput;
