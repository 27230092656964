import React, { useState } from 'react'
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { ReactComponent as EditIcon } from '../../../assets/Icons/edit-3.svg'
import BasicInfoDrawer from './BasicInfoDrawer';
import { useBasicInfo } from '../../../contexts/BasicInfo';

const BasicInfo = () => {
  const { data } = useBasicInfo();
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false);
  }

  const handleOpen = () => {
    setOpen(true);
  }


  return (
    <>
      <Box
        sx={{
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: 'border.main',
          p: 3,
          borderRadius: 2,
          minHeight: '100%'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: 1.5
          }}
        >
          <Typography variant='h5'>Basic Information</Typography>
          <IconButton
            sx={{ p: 0.375 }}
            onClick={handleOpen}
          >
            <EditIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 1.5,
            gap: 0.5
          }}
        >
          <Typography variant='body2' fontWeight={600}>First Name:</Typography>
          <Typography variant='body2' textTransform='capitalize'>{data.first_name}</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 1.5,
            gap: 0.5
          }}
        >
          <Typography variant='body2' fontWeight={600}>Last Name:</Typography>
          <Typography variant='body2' textTransform='capitalize'>{data.last_name}</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 0.5
          }}
        >
          <Typography variant='body2' fontWeight={600}>Professional Degree:</Typography>
          {data.degrees && data.degrees.length && <Typography variant='body2'>{data.degrees[0]?.name}</Typography>}
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 0.5
          }}
        >
          <Typography variant='body2' fontWeight={600}>Professional Headline:</Typography>
          <Typography variant='body2'>{data.info?.headline}</Typography>
        </Box>
      </Box>
      <BasicInfoDrawer
        open={open}
        onClose={handleClose}
      />
    </>
  )
}

export default BasicInfo