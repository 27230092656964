import React, { useEffect, useState } from 'react'
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';
import { ReactComponent as ChevronDown } from '../../assets/Icons/chevron-down.svg'
import { useCommon } from '../../contexts/common';
import { useCredentials } from '../../contexts/Credentials';
import { LOADING, SUCCESS } from '../../constants/common';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { profileCredentialsSchema } from '../../validations/profileValidations';

const CredentialsDrawer = ({ onClose, open, activeCredential }) => {
  const { statesList, fetchStatesList } = useCommon();
  const { generateCredential, createStatus, fetchCredentials, editCredential, updateStatus, updateCredentialstate, openConfirmationModal, deleteStatus } = useCredentials();
  const initialState = {
    name: '',
    title: '',
    state: 0,
    number: '',
    year: ''
  }

  const isEdit = Boolean(activeCredential);
  const [state, setState] = useState(initialState);


  const {
    control,
    handleSubmit,
    trigger,
    reset,
    formState: { errors, isValid },
    setValue,
  } = useForm({
    resolver: zodResolver(profileCredentialsSchema),
    mode: "onChange",
    defaultValues: {
      name: '',
      title: '',
      state: 0,
      number: '',
      year: ''
    },
  });

  const formValues = useWatch({ control });

  useEffect(() => {
    trigger();
    setState({ ...formValues });
  }, [formValues]);

  useEffect(() => {
    fetchStatesList()
  }, [])

  useEffect(() => {
    if (createStatus === SUCCESS) {
      updateCredentialstate('createStatus', '');
      fetchCredentials();
      handleClose();
    }
    if (updateStatus === SUCCESS) {
      updateCredentialstate('updateStatus', '');
      fetchCredentials();
      handleClose();
    }
  }, [createStatus, updateStatus])

  useEffect(() => {
    if (deleteStatus === SUCCESS) {
      handleClose();
    }
  }, [deleteStatus])

  useEffect(() => {
    reset();
    if (isEdit && open) {
      setValue('name', activeCredential?.data?.name);
      setValue('year', activeCredential?.data?.year);
      setValue('state', activeCredential?.data?.state);
      setValue('title', activeCredential?.data?.title);
      setValue('number', activeCredential?.data?.number);
    }
  }, [activeCredential, open, isEdit])


  const handleInput = (ev) => {
    setValue(`${ev.target.name}`, ev.target.value);
  }

  const handleClose = () => {
    onClose();
    setState(initialState);
  }

  const onSubmit = () => {
    if (isValid) {
      if (isEdit) {
        editCredential({
          credential_type: state.name,
          state_id: state.state,
          credential_data: state,
          credential_id: activeCredential?.id
        });
      } else {
        generateCredential({
          credential_type: state.name,
          state_id: state.state,
          credential_data: state
        });
      }
    }
  }

  return (
    <Drawer
      open={open}
      onClose={handleClose}
      anchor='right'
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          pt: 8
        }}
      >
        <Box pb={3} px={3}>
          <Typography variant='h4' mb={1}>Credential</Typography>
          <Typography variant='body1' mb={3}>
            Please indicate the state(s) in which you are licensed and the year of your first licensure:
          </Typography>
          <Divider sx={{ mb: 3 }} />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                name='name'
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    variant="outlined"
                    label='Credential Type'
                    fullWidth
                    error={!!errors.name}
                    helperText={errors.name?.message}
                    SelectProps={{
                      IconComponent: () => (
                        <Box
                          sx={{
                            minWidth: 24,
                            width: 24,
                            height: 24,
                            display: "inline-flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: 'text.secondary',
                            mr: 2,
                          }}
                        >
                          <ChevronDown width={12} height={6} />
                        </Box>
                      ),
                      MenuProps: {
                        PaperProps: {
                          style: {
                            maxHeight: 220,
                            width: 250,
                          },
                        },
                      },
                    }}
                    onChange={handleInput}
                    value={state.name}
                    disabled={isEdit}
                  >
                    <MenuItem value={''} disabled sx={{ py: 0 }} />
                    <MenuItem value='membership' sx={{ py: 1 }}>Membership</MenuItem>
                    <Divider sx={{ my: 1 }} />
                    <MenuItem value='licence' sx={{ py: 1 }}>License</MenuItem>
                  </TextField>
                )}
              />
            </Grid>
            {Boolean(state.name) && (
              <>
                <Grid item xs={12}>
                  <Controller
                    name='title'
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        label={`
                          ${state.name === 'membership' ? 'Membership Organization' : ''}
                          ${state.name === 'licence' ? 'License Issuer' : ''}
                          ${state.name === 'certificate' ? 'Accrediting Institution' : ''}
                        `}
                        fullWidth
                        value={state.title}
                        sx={{ mb: 4 }}
                        error={!!errors.title}
                        helperText={errors.title?.message}
                        onChange={handleInput}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name='state'
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        select
                        variant="outlined"
                        label='State'
                        fullWidth
                        error={!!errors.state}
                        helperText={errors.state?.message}
                        SelectProps={{
                          IconComponent: () => (
                            <Box
                              sx={{
                                minWidth: 24,
                                width: 24,
                                height: 24,
                                display: "inline-flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color: 'text.secondary',
                                mr: 2,
                              }}
                            >
                              <ChevronDown width={12} height={6} />
                            </Box>
                          ),
                          MenuProps: {
                            PaperProps: {
                              style: {
                                maxHeight: 220,
                                width: 250,
                              },
                            },
                          },
                        }}
                        onChange={handleInput}
                        value={state.state}
                      >
                        <MenuItem value={0} disabled />
                        {statesList.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name='number'
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        label={`
                          ${state.name === 'membership' ? 'Membership ID/Number' : ''}
                          ${state.name === 'licence' ? 'License Number' : ''}
                          ${state.name === 'certificate' ? 'Certificate ID/Number' : ''}
                        `}
                        fullWidth
                        value={state.number}
                        sx={{ mb: 4 }}
                        error={!!errors.number}
                        helperText={errors.number?.message}
                        onChange={handleInput}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name='year'
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        label='Year (YYYY)'
                        fullWidth
                        value={state.year}
                        sx={{ mb: 4 }}
                        error={!!errors.year}
                        helperText={errors.year?.message}
                        onChange={handleInput}
                      />
                    )}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Typography variant='body2' color='text.secondary'>
                Please note that the licensure information provided will be visible to other users when viewing your full profile.
              </Typography>
            </Grid>
            {isEdit && (
              <Grid item xs={12} display='flex' justifyContent='flex-end'>
                <Button
                  onClick={openConfirmationModal}
                  size='small'
                  color='error'
                  sx={{
                    minWidth: 120,
                    backgroundColor: 'error.light',
                    color: 'error.main',
                  }}
                >
                  Delete
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>


        <Box
          sx={{
            boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.08)',
            px: 1,
            pt: 3,
            pb: 5.625,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            position: 'sticky',
            width: '100%',
            backgroundColor: 'background.paper',
            bottom: 0,
            zIndex: 9
          }}
        >
          <Button
            variant='outlined'
            color='secondary'
            sx={{
              color: 'primary.main'
            }}
            size='large'
            fullWidth
            onClick={handleClose}
          >
            Cancel
          </Button>
          <LoadingButton
            variant='contained'
            size='large'
            fullWidth
            onClick={handleSubmit(onSubmit)}
            loading={createStatus === LOADING}
          >
            {isEdit ? 'Save' : 'Create'}
          </LoadingButton>
        </Box>
      </Box>
    </Drawer >
  )
}

export default CredentialsDrawer;