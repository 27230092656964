import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Paper from '@mui/material/Paper';

import { LOADING, SUCCESS, detox, payment_options, services, treatment } from '../../constants/common';
import { useGeneralProfile } from '../../contexts/Organization';

import { ReactComponent as EditIcon } from '../../assets/Icons/edit-3.svg'
import { ReactComponent as CloseIcon } from '../../assets/Icons/close.svg'
import { ReactComponent as ChevronDown } from '../../assets/Icons/chevron-down.svg'
import { ReactComponent as AddUserIcon } from '../../assets/Icons/user-add-plus-outlined.svg'
import CheckBox from '../../components/CheckBox';
import { useCommon } from '../../contexts/common';
import { profileAvailableServicesSchema } from '../../validations/profileValidations';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm, useWatch } from 'react-hook-form';

const AvailableServices = () => {
  const {
    editFacilityServices,
    updateFacilityServicesStatus,
    updateGeneralProfileState,
    facilityInfo
  } = useGeneralProfile();
  const {
    fetchTreatmentTypes,
    servicesList,
    detoxList,
    paymentOptionsList,
  } = useCommon();
  const initalState = {
    available_services: [],
    detox_for: [],
    payment_options: []
  }
  const [isEditMode, setIsEditMode] = useState(false);
  const [state, setState] = useState(initalState);
  const [isInvalid, setIsInvalid] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    trigger,
  } = useForm({
    resolver: zodResolver(profileAvailableServicesSchema),
    mode: 'onChange',
    defaultValues: {
      available_services: [],
      detox_for: [],
      payment_options: []
    },
  });

  const formValues = useWatch({ control });

  useEffect(() => {
    setState(prev => ({ ...prev, ...formValues }))
  }, [formValues]);

  const handleInput = (ev) => {
    setValue(`${ev.target.name}`, ev.target.value);
    trigger();
  }

  const handleCheckBoxInput = (ev) => {
    setState((prevState) => ({
      ...prevState,
      [ev.target.name]: ev.target.checked
    }))
  }

  const handleOpenEditMode = () => {
    setIsEditMode(true)
  }

  const handleCloseEditMode = () => {
    setIsEditMode(false)
    setIsInvalid(false);
    setState({
      available_services: (facilityInfo?.info?.available_services || [])?.map(({ id }) => id),
      detox_for: (facilityInfo?.info?.detox_for || [])?.map(({ id }) => id),
      payment_options: (facilityInfo?.info?.payment_options || [])?.map(({ id }) => id),
      medication_assisted_treatment: facilityInfo?.info?.medication_assisted_treatment
    })
  }
  const onSubmit = (ev) => {
    if (isValid) {
      editFacilityServices(state);
    }
  }

  useEffect(() => {
    if (updateFacilityServicesStatus === SUCCESS) {
      updateGeneralProfileState('updateFacilityServicesStatus', '')
      setIsEditMode(false);
      setIsInvalid(false);
    }
  }, [updateFacilityServicesStatus])

  useEffect(() => {
    if (facilityInfo) {
      setValue('available_services', (facilityInfo?.info?.available_services || [])?.map(({ id }) => id));
      setValue('detox_for', (facilityInfo?.info?.detox_for || [])?.map(({ id }) => id));
      setValue('payment_options', (facilityInfo?.info?.payment_options || [])?.map(({ id }) => id));
      setValue('medication_assisted_treatment', (facilityInfo?.info?.medication_assisted_treatment));
    }
  }, [facilityInfo])

  useEffect(() => {
    fetchTreatmentTypes(treatment);
    fetchTreatmentTypes(services);
    fetchTreatmentTypes(detox);
    fetchTreatmentTypes(payment_options);
  }, [])

  return (
    <Paper
      sx={{
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        gap: 3
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: 'border.dark'
            }}
          >
            <AddUserIcon width={24} height={24} />
          </Box>
          <Typography variant='h4' ml={1}>Services Available</Typography>
        </Box>
        {isEditMode ? (
          <Box>
            <LoadingButton
              type='submit'
              loading={updateFacilityServicesStatus === LOADING}
              size='small'
              onClick={handleSubmit(onSubmit)}
            >
              Save
            </LoadingButton>
            <IconButton
              sx={{ p: 0.375 }}
              onClick={handleCloseEditMode}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        ) : (
          <IconButton
            sx={{ p: 0.375 }}
            onClick={handleOpenEditMode}
          >
            <EditIcon />
          </IconButton>
        )}
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControl fullWidth error={!!errors.available_services}>
            <InputLabel id='available-services-label'>Available Services</InputLabel>
            <Controller
              name='available_services'
              control={control}
              render={({ field }) => (
                <Controller
                  name='available_services'
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      labelId='available-services-label'
                      label='Available Services'
                      fullWidth
                      multiple
                      renderValue={(selected) => selected.map((val) => servicesList.find((item) => item.id === +val)?.name).join(', ')}
                      disabled={!isEditMode}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 224,
                            width: 250,
                          },
                        },
                      }}
                      IconComponent={(props) => (
                        <Box
                          {...props}
                          component='span'
                          sx={{
                            minWidth: 24,
                            width: 24,
                            height: 24,
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            top: '50% !important',
                            right: '16px !important',
                            transform: 'translateY(-50%) !important',
                          }}
                        >
                          <ChevronDown width={12} height={6} />
                        </Box>
                      )}
                      onChange={handleInput}
                      value={field.value}
                    >
                      <MenuItem value={null} disabled>
                        <em>Select services</em>
                      </MenuItem>
                      {servicesList.map((item) => (
                        <MenuItem
                          sx={{ py: 1 }}
                          value={item.id}
                          key={item.id}
                        >
                          <Checkbox checked={field.value.indexOf(item.id) > -1} />
                          <Typography variant='body2'>{item.name}</Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              )}
            />
            {errors.available_services && (
              <Typography fontSize={13} color='error'>{errors.available_services.message}</Typography>
            )}
          </FormControl>
        </Grid>
        {Boolean((facilityInfo?.info?.facility_types || []).find((item) => [23, 29].includes(item.id))) && (
          <Grid item xs={12}>
            <FormControl fullWidth error={!!errors.detox_for}>
              <InputLabel id='detox-for-label'>Detox For</InputLabel>
              <Controller
                name='detox_for'
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    labelId='detox-for-label'
                    label='Detox For'
                    fullWidth
                    multiple
                    renderValue={(selected) => selected.map((val) => detoxList.find((item) => item.id === +val)?.name).join(', ')}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 224,
                          width: 250,
                        },
                      },
                    }}
                    IconComponent={(props) => (
                      <Box
                        {...props}
                        component='span'
                        sx={{
                          minWidth: 24,
                          width: 24,
                          height: 24,
                          display: 'inline-flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          top: '50% !important',
                          right: '16px !important',
                          transform: 'translateY(-50%) !important',
                        }}
                      >
                        <ChevronDown width={12} height={6} />
                      </Box>
                    )}
                    onChange={handleInput}
                    value={state.detox_for || []}
                    error={isInvalid && (state.detox_for || []).length < 1}
                    disabled={!isEditMode}
                  >
                    <MenuItem
                      sx={{
                        p: 0
                      }}
                      value={null}
                      disabled
                    />
                    {detoxList.map((item) => (
                      <MenuItem
                        sx={{
                          py: 1,
                          gap: 1
                        }}
                        value={item.id}
                        key={item.id}
                      >
                        <Checkbox sx={{ p: 0 }} checked={Boolean((state.detox_for || []).find((val) => +val === item.id))} />
                        <Typography variant='body2'>{item.name}</Typography>
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors.detox_for && (
                <Typography fontSize={13} color='error'>{errors.detox_for?.message}</Typography>
              )}
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12}>
          <FormControl fullWidth error={!!errors?.payment_options}>
            <InputLabel id='payment-options-label'>Payment options</InputLabel>
            <Select
              labelId='payment-options-label'
              label='Payment options'
              fullWidth
              name='payment_options'
              multiple
              renderValue={(selected) => selected.map((val) => paymentOptionsList.find((item) => item.id === +val)?.name).join(', ')}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 224,
                    width: 250,
                  },
                },
              }}
              IconComponent={(props) => (
                <Box
                  {...props}
                  component='span'
                  sx={{
                    minWidth: 24,
                    width: 24,
                    height: 24,
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    top: '50% !important',
                    right: '16px !important',
                    transform: 'translateY(-50%) !important',
                  }}
                >
                  <ChevronDown width={12} height={6} />
                </Box>
              )}
              onChange={handleInput}
              value={state.payment_options || []}
              error={!!errors?.payment_options}
              disabled={!isEditMode}
            >
              <MenuItem
                sx={{
                  p: 0
                }}
                value={null}
                disabled
              />
              {paymentOptionsList.map((item) => (
                <MenuItem
                  sx={{
                    py: 1,
                    gap: 1
                  }}
                  value={item.id}
                  key={item.id}
                >
                  <Checkbox sx={{ p: 0 }} checked={Boolean((state.payment_options || []).find((val) => +val === item.id))} />
                  <Typography variant='body2'>{item.name}</Typography>
                </MenuItem>
              ))}
            </Select>
            {errors.payment_options && (
              <Typography fontSize={13} color='error'>{errors.payment_options.message}</Typography>
            )}
          </FormControl>
        </Grid>
      </Grid>
      <CheckBox
        label='Medication-assisted Treatment'
        onChange={handleCheckBoxInput}
        name='medication_assisted_treatment'
        checked={Boolean(state.medication_assisted_treatment)}
        disabled={!isEditMode}
      />
    </Paper>
  )
}

export default AvailableServices