import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import BasicInfo from '../../components/BasicProfile/BasicInfo';
import { ReactComponent as UserIcon } from '../../assets/Icons/user.svg'
import { ReactComponent as UploadIcon } from '../../assets/Icons/upload-cloud.svg'
import AboutMe from '../../components/BasicProfile/AboutMe';
import Contact from '../../components/BasicProfile/Contact';
import OfficeInfo from '../../components/BasicProfile/OfficeInfo';
import AddressCard from '../../components/BasicProfile/AddressCard';
import LinksCard from '../../components/BasicProfile/LinksCard';
import { useBasicInfo } from '../../contexts/BasicInfo';
import { LOADING, SUCCESS } from '../../constants/common';
import { useAlert } from '../../contexts/Alert';
import { useForm, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { imageTypes } from '../../constants/lists';
import { profileImageValidationSchema } from '../../validations/profileValidations';

const ProfileBasic = () => {
  const { sendAlert } = useAlert();
  const { fetchInfo, editProfilePhoto, updatePhotoStatus, updateInfoState, data } = useBasicInfo();
  const [profileImageURL, setProfileImageURL] = useState(null);
  const [imageError, setImageError] = useState(null);

  const {
    control,
    formState: { errors},
    setValue,
  } = useForm({
    resolver: zodResolver(profileImageValidationSchema),
    mode: "onChange",
    defaultValues: {
      main_image: data?.main_image || null,
    },
  });

  const formValues = useWatch({ control });

  useEffect(() => {
    setProfileImageURL(formValues.main_image);
  }, [formValues]);

  useEffect(() => {
    setImageError(errors.main_image?.message)
  }, [errors.main_image]?.message);

  useEffect(() => {
    if (data?.main_image) {
      handleFileRead(data?.main_image);
    }
  }, [data?.main_image]);

  const handleFileRead = (file) => {
    if (!file || !file.type?.startsWith('image/')) {
      setProfileImageURL(null);
      return;
    }
    const reader = new FileReader();
    reader.onload = (event) => {
      setProfileImageURL(event.target.result);
    };
    reader.onerror = () => {
      console.error('Error reading the file.');
      sendAlert("There is an issue while reading this file", "error")
    };
    reader.readAsDataURL(file);
  };

  function handleFileInput(ev) {
    const file = ev.target.files?.[0];
    console.log(file);
    if (!file) {
      console.log('here');
      setProfileImageURL(null);
      setValue('main_image', null);
      return;
    }
    if (file.size > 5 * 1024 * 1024) { // 5 MB
      errors['main_image'] = { message: 'The logo must be less than 5 MB.' };
      setProfileImageURL(null);
      setValue('main_image', null);
      return;
    }

    if (!imageTypes.includes(file.type)) { // 5 MB
      // sendAlert('The logo must be less than 5 MB.', 'error');
      errors['main_image'] = { message: 'Please upload a valid image file.\n(jpeg, jpg, png, svg+xml)' };
      setProfileImageURL(null);
      setValue('main_image', null);
      return;
    }

    if (!file.type.startsWith('image/')) {
      // sendAlert('Please select a valid image file.', 'error');
      setProfileImageURL(null);
      setValue('main_image', null);
      errors['main_image'] = { message: 'Please select a valid image file.' };
      return;
    }

    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = () => {
      const width = img.width;
      const height = img.height;

      if (width < 300 || height < 300) {
      console.log('here4')
        errors['main_image'] = { message: 'The image dimensions should be at least 300x300 pixels.' };
        setProfileImageURL(null);
        setValue('main_image', null);
        return;
      }

      if (width > 2000 || height > 2000) {
      console.log('here5')
        errors['main_image'] = { message: 'The image dimensions should not exceed 2000x2000 pixels.' };
        setProfileImageURL(null);
        setValue('main_image', null);
        return;
      }
      setValue('main_image', file);
      errors['main_image'] = {};
      setImageError('');
      console.log(profileImageURL)
      if (profileImageURL) {
        editProfilePhoto(file);
      }
      handleFileRead(file);
    }
    img.onerror = () => {
      errors['main_image'] = { message: 'There was an error loading the image. Please select a valid image file.' };
      setProfileImageURL({});
      setValue('main_image', {});
      return;
    };
  }

  useEffect(() => {
    fetchInfo()
  }, [])

  useEffect(() => {
    if (updatePhotoStatus === SUCCESS) {
      updateInfoState('updatePhotoStatus', '')
      fetchInfo()
    }
  }, [updatePhotoStatus])

  return (
    <Paper
      sx={{
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        gap: 3
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <UserIcon width={24} height={24} />
        <Typography variant='h4' ml={1}>Information</Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <BasicInfo />
        </Grid>
        <Grid item xs={4}>
          <Box
            sx={{
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: 'border.main',
              p: 3,
              borderRadius: 2
            }}
          >
            <Typography variant='h5' mb={1.5}>Upload an Image</Typography>
            {updatePhotoStatus === LOADING ? (
              <Box
                sx={{
                  border: '1px dashed',
                  borderColor: 'border.dark',
                  borderRadius: 1.5,
                  height: 158,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  position: 'relative',
                  '&:hover': {
                    backgroundColor: 'primary.light'
                  }
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Box
                sx={{
                  border: '1px dashed',
                  borderColor: 'border.dark',
                  borderRadius: 1.5,
                  height: 158,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  position: 'relative',
                  '&:hover': {
                    backgroundColor: 'primary.light'
                  }
                }}
                component='label'
              >
                {profileImageURL ? (
                  <>
                    <Avatar
                      src={profileImageURL}
                      sx={{ width: '100%', height: 'auto', borderRadius: 0 }}
                    />
                    <Typography
                      variant='body2'
                      sx={{
                        textAlign: 'center',
                        position: 'absolute',
                        bottom: 0,
                        backgroundColor: 'rgba(0,0,0,0.4)',
                        width: '100%',
                        color: 'background.paper'
                      }}
                    >
                      Edit
                    </Typography>
                  </>
                ) : (
                  <Button
                    startIcon={<UploadIcon />}
                    sx={{
                      p: 0,
                      '&:hover': {
                        backgroundColor: 'transparent'
                      }
                    }}
                    component='span'
                  >
                    Upload
                  </Button>
                )}
                <input
                  type='file'
                  accept='image/*'
                  onChange={handleFileInput}
                  style={{ display: 'none', overflow: 'hidden' }}
                  name='main_image'
                  error={!!errors.main_image}
                  helperText={errors.main_image?.message} />
                {!!errors.main_image &&
                  <Typography variant="caption" color="error" sx={{ textAlign: 'center', p: 1 }}>
                    {errors.main_image?.message}
                  </Typography>
                }

              </Box>
            )}

          </Box>
        </Grid>
      </Grid>
      <AboutMe />
      <Contact />
      <AddressCard />
      <LinksCard />
      <OfficeInfo />
    </Paper>
  )
}

export default ProfileBasic;