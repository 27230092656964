import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { ReactComponent as EditIcon } from '../../../assets/Icons/edit-3.svg'
import { ReactComponent as CloseIcon } from '../../../assets/Icons/close.svg'
import { ReactComponent as ChevronDown } from '../../../assets/Icons/chevron-down.svg'
import { useGeneralProfile } from '../../../contexts/Organization';
import { LOADING, SUCCESS, facility_types } from '../../../constants/common';
import { useCommon } from '../../../contexts/common';
import { Checkbox } from '@mui/material';
import PhoneNumberInput from '../../../components/PhoneNumberInput';
import { basicTreatmentProgramSchema } from '../../../validations/profileValidations';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { formatUrl } from '../../../utils/helper';

const BasicInfo = () => {
  const {
    editFacilityBasicInfo,
    updateFacilityBasicInfoStatus,
    updateGeneralProfileState,
    facilityInfo,
    fetchFacilityInfo
  } = useGeneralProfile();
  const { fetchTreatmentTypes, facilityTypesList } = useCommon();

  const initalState = {
    facility_name: '',
    business_number: '',
    website_link: '',
    facility_type_ids: []
  }

  const [isEditMode, setIsEditMode] = useState(false);
  const [state, setState] = useState(initalState);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    trigger,
  } = useForm({
    resolver: zodResolver(basicTreatmentProgramSchema),
    mode: 'onChange',
    defaultValues: {
      facility_name: facilityInfo.info?.facility_name || '',
      business_number: facilityInfo.info?.business_number || '',
      website_link: facilityInfo.info?.website_link || '',
      facility_type_ids: facilityInfo.info?.facility_type_ids?.map(item => item.id) || []
    },
  });

  const formValues = useWatch({ control });

  useEffect(() => {
    fetchTreatmentTypes(facility_types);
  }, []);

  useEffect(() => {
    setState(prev => ({ ...prev, ...formValues }));
  }, [formValues]);

  const handleInput = (ev) => {
    setValue(`${ev.target.name}`, ev.target.value);
  }

  const handlePhoneNumber = (ev) => {
    setValue(`${Object.keys(ev)[0]}`, Object.values(ev)[0])
  }

  const handleOpenEditMode = () => {
    setIsEditMode(true)
  }

  const handleCloseEditMode = () => {
    setIsEditMode(false)
    setState({
      facility_name: facilityInfo.info?.facility_name || '',
      business_number: facilityInfo?.info?.business_number || '',
      website_link: facilityInfo?.info?.website_link || '',
      facility_type_ids: facilityInfo.info?.facility_types?.map((item) => item.id) || [],
    })
  }

  const onSubmit = () => {
    if (isValid) {
      editFacilityBasicInfo(state);
    }
  }

  useEffect(() => {
    if (updateFacilityBasicInfoStatus === SUCCESS) {
      updateGeneralProfileState('updateFacilityBasicInfoStatus', '')
      setIsEditMode(false)
      fetchFacilityInfo();
    }
  }, [updateFacilityBasicInfoStatus])

  useEffect(() => {
    if (facilityInfo) {
      setValue('facility_name', facilityInfo?.info?.facility_name || '');
      setValue('business_number', facilityInfo?.info?.business_number || '');
      setValue('website_link', facilityInfo?.info?.website_link || '');
      setValue('facility_type_ids', facilityInfo?.info?.facility_type_ids || []);
    }
  }, [facilityInfo])

  return (
    <Box
      sx={{
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'border.main',
        p: 3,
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 3
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant='h5'>Basic Information</Typography>
        {isEditMode ? (
          <Box>
            <LoadingButton
              onClick={handleSubmit(onSubmit)}
              loading={updateFacilityBasicInfoStatus === LOADING}
              size='small'
            >
              Save
            </LoadingButton>
            <IconButton
              sx={{ p: 0.375 }}
              onClick={handleCloseEditMode}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        ) : (
          <IconButton
            sx={{ p: 0.375 }}
            onClick={handleOpenEditMode}
          >
            <EditIcon />
          </IconButton>
        )}
      </Box>
      <Controller
        name='facility_name'
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            variant='outlined'
            label='Name'
            value={state.facility_name}
            error={!!errors?.facility_name}
            helperText={errors?.facility_name?.message}
            disabled={!isEditMode}
          />
        )}
      />
      <PhoneNumberInput
        control={control}
        fullWidth
        variant='outlined'
        sx={{ mb: 3 }}
        name='business_number'
        label='Business Phone Number'
        value={state.business_number}
        onChange={handlePhoneNumber}
        error={!!errors.business_number}
        helperText={errors.business_number?.message}
        trigger={trigger}
        disabled={!isEditMode}
      />
      <Controller
        name='website_link'
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            variant='outlined'
            label='Company website'
            fullWidth
            error={!!errors.website_link}
            helperText={errors.website_link?.message}
            value={state.website_link}
            onChange={(e) => {
              const value = e.target.value;
              if (value === '' || value === `https://`) {
                field.onChange('');
                return;
              }
              const formattedValue = formatUrl(value);
              field.onChange(formattedValue);
              setState(prev => ({ ...prev, [e.target.name]: e.target.value }))
            }}
            disabled={!isEditMode}
          />
        )}
      />
      <FormControl fullWidth>
        <InputLabel id='facility-type-label'>Treatment Facility Type</InputLabel>
        <Controller
          name='facility_type_ids'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              select
              multiple
              variant='outlined'
              labelId='facility-type-label'
              label='Treatment Facility Type'
              onChange={handleInput}
              renderValue={(selected) => selected.map((val) => facilityTypesList?.find((item) => item.id))}
              value={state.facility_type_ids || []}
              fullWidth
              error={!!errors.facility_type_ids}
              helperText={errors.facility_type_ids?.message}
              disabled={!isEditMode}
              SelectProps={{
                IconComponent: (props) => (
                  <Box
                    {...props}
                    component='span'
                    sx={{
                      minWidth: 24,
                      width: 24,
                      height: 24,
                      display: 'inline-flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      top: '50% !important',
                      right: '16px !important',
                      transform: 'translateY(-50%) !important',
                    }}
                  >
                    <ChevronDown width={12} height={6} />
                  </Box>
                ),
                MenuProps: {
                  PaperProps: {
                    style: {
                      maxHeight: 220,
                      width: 250,
                    },
                  },
                },
              }}
            >
              <MenuItem
                sx={{
                  p: 0
                }}
                value={null}
                disabled
              />
              {facilityTypesList.map((item) => (
                <MenuItem
                  sx={{
                    py: 1,
                    gap: 1
                  }}
                  value={item.id}
                  key={item.id}
                >
                  {state.facility_type_ids}
                  <Checkbox sx={{ p: 0 }} checked={Boolean(([]).find((val) => +val === item.id))} />
                  <Typography variant='body2'>{item.name}</Typography>
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </FormControl>
    </Box >
  )
}

export default BasicInfo