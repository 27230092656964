import React, { useEffect, useState } from 'react'
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';
import { useBasicInfo } from '../../../../contexts/BasicInfo';
import { LOADING, SUCCESS } from '../../../../constants/common';
import { ReactComponent as ChevronDown } from '../../../../assets/Icons/chevron-down.svg'
import { profileBasicInfoSchema } from '../../../../validations/profileValidations';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { MenuItem } from '@mui/material';
import { useDegrees } from '../../../../contexts/Degrees';

const BasicInfoDrawer = ({ onClose, open }) => {
  const { editBasicInfo, updateInfoState, updateBasicInfoStatus, fetchInfo, data } = useBasicInfo();
  const initialState = {
    first_name: '',
    last_name: '',
    headline: '',
    professional_degree_id: 0
  }

  const {
    list,
    fetchStatus,
    fetchUserDegrees,
  } = useDegrees();

  const [state, setState] = useState(initialState);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
  } = useForm({
    resolver: zodResolver(profileBasicInfoSchema),
    mode: "onChange",
    defaultValues: {
      first_name: data.first_name || '',
      last_name: data.last_name || '',
      headline: data.info?.headline || '',
      professional_degree_id: data?.info?.degree || null
    },
  });

  const formValues = useWatch({ control });


  useEffect(() => {
    setState(prev => ({ ...prev, ...formValues }));
  }, [formValues]);

  useEffect(() => {
    if (updateBasicInfoStatus === SUCCESS) {
      updateInfoState('updateBasicInfoStatus', '')
      fetchInfo();
      handleClose();
    }
  }, [updateBasicInfoStatus]);

  useEffect(() => {
    if (data && open) {
      fetchUserDegrees();
      setValue('first_name', data.first_name);
      setValue('last_name', data.last_name);
      setValue('headline', data.info?.headline);
      setValue('professional_degree_id', (data.degrees && data.degrees.length > 0) ? data.degrees[0]?.id : null);
    }
  }, [data, open]);


  const handleClose = () => {
    setState(initialState);
    onClose();
  }

  const onSubmit = () => {
    if (isValid)
      editBasicInfo(state);
  }


  return (
    <Drawer
      open={open}
      onClose={handleClose}
      anchor='right'
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          pt: 8
        }}
      >
        <Box pb={3} px={3}>
          <Typography variant='h4' mb={1}>Basic Information</Typography>
          <Divider sx={{ mb: 4 }} />
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Controller
                name="first_name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    label="First Name"
                    fullWidth
                    error={!!errors.first_name}
                    helperText={errors.first_name?.message}
                    value={state.first_name}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="last_name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    label="Last Name"
                    fullWidth
                    error={!!errors.last_name}
                    helperText={errors.last_name?.message}
                    value={state.last_name}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>

              <Controller
                name={'professional_degree_id'}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    variant='outlined'
                    label='Professional Degree'
                    fullWidth
                    SelectProps={{
                      IconComponent: () => (
                        <Box
                          sx={{
                            minWidth: 24,
                            width: 24,
                            height: 24,
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            mr: 2,
                          }}
                        >
                          <ChevronDown width={12} height={6} />
                        </Box>
                      ),
                      MenuProps: {
                        PaperProps: {
                          style: {
                            maxHeight: 220,
                            width: 250,
                          },
                        },
                      },
                    }}
                    // onChange={onChange}
                    value={state?.professional_degree_id || ''}
                    error={!!errors?.professional_degree_id}
                    helperText={
                      errors?.professional_degree_id?.message
                    }
                  >
                    <MenuItem value={0}>
                      Do not show
                    </MenuItem>
                    {fetchStatus === 'SUCCESS' && list?.map((degree) => (
                      <MenuItem key={degree.id} value={parseInt(degree.professional_degree_id)}>
                        {degree.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name='headline'
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant='outlined'
                    label='Headline'
                    fullWidth
                    error={!!errors.headline}
                    helperText={errors.headline?.message}
                    value={state.headline}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.08)',
            px: 1,
            pt: 3,
            pb: 5.625,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            position: 'sticky',
            width: '100%',
            backgroundColor: 'background.paper',
            bottom: 0,
            zIndex: 9
          }}
        >
          <Button
            variant='outlined'
            color='secondary'
            sx={{
              color: 'primary.main'
            }}
            size='large'
            fullWidth
            onClick={handleClose}
          >
            Cancel
          </Button>
          <LoadingButton
            variant='contained'
            size='large'
            fullWidth
            onClick={handleSubmit(onSubmit)}
            loading={updateBasicInfoStatus === LOADING}
          >
            Save
          </LoadingButton>
        </Box>
      </Box>
    </Drawer>
  )
}

export default BasicInfoDrawer;