import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as GroupIcon } from "../../assets/Icons/no-poverty.svg";
// import { ReactComponent as PhoneIcon } from '../../assets/Icons/phone.svg';
import { ReactComponent as DeskCustomerIcon } from "../../assets/Icons/desk-customer.svg";
import { ReactComponent as StickyNoteIcon } from "../../assets/Icons/sticky-note.svg";
import { ReactComponent as AddUserIcon } from "../../assets/Icons/user-add-plus.svg";
import { ReactComponent as ShareIcon } from "../../assets/Icons/share-transmit.svg";
import { ReactComponent as HeartIcon } from "../../assets/Icons/heart.svg";
import { ReactComponent as HeartFilledIcon } from "../../assets/Icons/heart-filled.svg";
import { ReactComponent as SendIcon } from "../../assets/Icons/send-filled.svg";
import {
  ERROR,
  SUCCESS,
  everyone,
  full_and_need_to_refer_out,
  only_from_my_connections,
  only_from_my_favorites,
  treatment_program,
} from "../../constants/common";
import { useNetwork } from "../../contexts/NetWork";
import { useBasicInfo } from "../../contexts/BasicInfo";
import paths from "../../constants/paths";
import { useUserProfile } from "../../contexts/UserProfile";
import { ReactComponent as UserAddIcon } from "../../assets/Icons/user-add-plus-outlined.svg";
import { useAuth } from "../../contexts/Auth";
import { ReactComponent as MinusUserIcon } from "../../assets/Icons/user-minus.svg";
import { deleteConnection } from "../../services/network.services";
import { useAlert } from "../../contexts/Alert";
import { useLocation, useParams } from 'react-router-dom';
import useResize from "../../hooks/useResize";
import useFilters from "../../hooks/useFilter";
import { trackClickEvent } from "../../mixpanel";

const UserCard = ({ data, onReferralClick, onShareClick = null, clearSearch = null, enableShare = true, enableReferral = true }) => {
  const { connectWithOthers, connectStatus, updateNetworkState } = useNetwork();
  const { data: info } = useBasicInfo();
  const { likeUser } = useUserProfile();
  const [loading, setLoading] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const { account_type } = useAuth();
  const userData = data?.connected_user || data?.favorite_user || data;
  const isMobileView = useResize(600);
  const { sendAlert } = useAlert();
  const location = useLocation();
  const { pathname } = location;

  const isUserProfileRoute = pathname.startsWith('/user-profile');
  const userId = new URLSearchParams(location.search).get('account_type');
  const { id } = useParams();
  const showRemoveIcon = isUserProfileRoute && userId === 'user' && id === info.uuid;
  const [filters] = useFilters();

  useEffect(() => {
    if (connectStatus === SUCCESS) {
      updateNetworkState("connectStatus", "");
    }
    if ([SUCCESS, ERROR].includes(connectStatus)) setLoading(false);
  }, [connectStatus]);

  useEffect(() => {
    setIsLiked(data?.is_fav === 0 || data?.is_favourite === 0 ? false : true);
  }, [data]);

  const favouriteUser = () => {
    likeUser(
      {
        favorite_user_id: userData.id,
        account_type: account_type === treatment_program ? treatment_program : "user",
      },
      isLiked,
      true
    );
    setIsLiked(prevState => !prevState);
    trackClickEvent(
      isLiked ? 'Add Like' : 'Remove Like', 
      { page: 'Profile', component: 'User card', button: isLiked ? 'like' : 'unlike'}
    );
  }

  const connect = () => {
    setLoading(true);
    connectWithOthers({
      connected_user_id: userData.id,
      account_type: account_type === treatment_program ? treatment_program : "user",
    });
    trackClickEvent(
      'Create Connection', 
      { page: 'Profile', component: 'User card', button: 'connect'}
    );
  }

  const isSameUser = () => {
    return String(userData.id) ===  String(localStorage.getItem('userId'));
  }

  const handleReferralClick = () => {
    trackClickEvent(
      'Send Referral', 
      { button: 'Send Referral', page: 'Network', component: 'User Card' }
    )
    onReferralClick(data);
  }

  const handleShareClick = () => {
    trackClickEvent(
      'Share User', 
      { button: 'Share', page: 'Network', component: 'User Card' }
    )
    onShareClick(data);
  }

  // const { provider } = filters;
  const isTreatment =
    userData?.account_type === treatment_program &&
    userData?.roles?.length === 1;
  const isTreatmentOwner = userData?.treatment_user_type === "treatment_owner";
  const age_served = isTreatment
    ? userData?.treatment_program_info?.age_served
    : userData.info?.age_served;
  const address = isTreatment
    ? userData?.treatment_program_info?.address
    : userData?.info?.address;
  const currentStatus = isTreatment
    ? userData?.treatment_program_info?.current_status
    : userData?.info?.current_status;
  const clientTypes = isTreatment ? userData?.treatment_program_info?.clientTypes : userData.client_types
  const cardImage = isTreatment ? userData?.treatment_program_info?.facility_image : userData.main_image;
  const contentStyleDesktop = {
    flexDirection: isMobileView ? "row" : "column",
    display: "flex",
  };
  const contentStyleMobile = {
    flexDirection: isMobileView ? "row" : "column",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "12px",
    width: "100%",
  };
  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        gap: 2.25,
        p: isMobileView ? 0 : 1,
        borderRadius: 3,
        p: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: { xs: "center", sm: "flex-start" },
          flexDirection: { xs: "column", sm: "row" },
          gap: 2,
          minWidth: "calc(100% - 156px)",
          maxWidth: { md: "calc(100% - 156px)" },
          flexBasis: { md: "calc(100% - 156px)" },
        }}
      >
        {!isMobileView && (
          <Box position="relative" display="inline-block" textAlign="center">
            <Avatar
              sx={{
                width: 140,
                height: 140,
                borderRadius: 4,
              }}
              src={cardImage}
            />
            {currentStatus && (
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  height: '40%',
                  background: `linear-gradient(to top, ${currentStatus === full_and_need_to_refer_out ? 'red' : 'green'}, transparent)`,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderBottomLeftRadius: 16,
                  borderBottomRightRadius: 16,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: 'white',
                    mt: 3 ,
                    fontWeight: 'bold',
                    fontSize: '0.8rem',
                    textShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
                  }}
                >
                  {currentStatus === full_and_need_to_refer_out ? 'Fully Booked' : 'Accepting Clients'}
                </Typography>
              </Box>
            )}
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box sx={!isMobileView ? contentStyleDesktop : contentStyleMobile}>
            {isMobileView && (
              <Avatar
                sx={{
                  width: 64,
                  height: 64,
                  borderRadius: 1,
                }}
                src={cardImage}
              />
            )}
            <Box>
              <Box>
                <Link
                  href={paths.userProfile(
                    userData?.uuid,
                    isTreatment ? treatment_program : "user"
                  )}
                  variant="h5"
                  color="text.primary"
                  textTransform="capitalize"
                  fontWeight={700}
                  sx={{
                    fontSize: "14px",
                    display: 'block',
                    lineHeight: '15px',
                    mb: 0.75
                  }}
                >
                  {(isTreatment && filters?.provider === 'treatment_program') ? (
                    <>
                      {userData.treatment_program_info?.facility_name}
                    </>
                  ) : (
                    <>
                      {userData?.first_name} {userData?.last_name}
                      {(userData?.info?.title || userData?.degrees?.length > 0) &&
                        `, ${userData?.degrees?.length > 0
                          ? userData?.degrees[0]?.degree?.name
                          : userData?.info?.title
                        }`}
                    </>
                  )}
                </Link>
              </Box>

              {userData?.account_type === treatment_program && !isTreatmentOwner ? (
                <Box>
                  <Link
                    href={paths.userProfile(
                      userData?.uuid,
                      treatment_program
                    )}
                    variant="h5"
                    color="text.secondary"
                    textTransform="capitalize"
                    fontWeight={400}
                    sx={{
                      fontSize: "14px",
                      display: 'block',
                      lineHeight: '15px',
                      mt: 0.75,
                      mb: 1
                    }}
                  >
                    {userData.treatment_program_info?.facility_name}
                  </Link>
                </Box>
              ) : null}
              {address?.address1.length > 0 ? (
                <Typography
                  variant="body2"
                  sx={{ color: "#61676B", fontSize: "12px" }}
                >
                  {address?.address1}
                  {address?.city && `, ${address?.city}`}
                  {address?.state?.name && `, ${address?.state?.abbreviation}`}
                  {address?.zip_code && `, ${address?.zip_code}`}
                </Typography>
              ) : null}
              {isMobileView ? (
                <Typography
                  variant="body2"
                  sx={{
                    color:
                      currentStatus !== full_and_need_to_refer_out
                        ? "#004612"
                        : "#AC553A",
                    backgroundColor:
                      currentStatus !== full_and_need_to_refer_out
                        ? "#E7F8EB"
                        : "error.light",
                    borderRadius: 1,
                    py: 0.25,
                    px: 1,
                    textTransform: "capitalize",
                    display: "inline-block",
                    mb: 0.5,
                    lineHeight: "17px",
                    fontSize: "14px",
                  }}
                >
                  {currentStatus !== full_and_need_to_refer_out
                    ? "Accepting Clients"
                    : "Fully Booked"}
                </Typography>
              ) : null}
            </Box>
          </Box>
          {/* <Box>
            {isMobileView && currentStatus === full_and_need_to_refer_out ? (
              <Typography
                variant="body2"
                sx={{ color: "#61676B", fontSize: "12px" }}
              >
                {userData?.treatment_program_info?.about_me}
              </Typography>
            ) : null}
          </Box> */}
          <Box>
            {/* {!isMobileView ? (
              <>
                {currentStatus === full_and_need_to_refer_out ? (
                  <Typography
                    variant="body2"
                    sx={{ color: "#61676B", fontSize: "12px" }}
                  >
                    {userData?.treatment_program_info?.about_me}
                  </Typography>
                ) : null}
              </>
            ) : null} */}
            {(clientTypes?.length > 0 ||
              age_served?.length > 0 ||
              Boolean(
                userData?.treatment_program_info
                  ?.treatment_type?.name
              )) &&
              currentStatus !== full_and_need_to_refer_out ? (
              <>
                {clientTypes?.length > 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 0.5,
                      mb: 0.5,
                      mt: 1,
                    }}
                  >
                    {!isMobileView && (
                      <Box color="text.secondary" display="flex">
                        <GroupIcon width={14} height={14} />
                      </Box>
                    )}
                    {isMobileView ? (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            display: "-webkit-box",
                            WebkitLineClamp: "1",
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            color: "#61676B",
                            fontSize: "10px",
                            fontWeight: 500,
                            mt: 1.5,
                          }}
                        >
                          CURRENTLY ACCEPTING:
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            display: "-webkit-box",
                            WebkitLineClamp: "1",
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            fontSize: "14px",
                          }}
                        >
                          {data
                            ?.map((item) => item?.name)
                            .join(", ")}
                        </Typography>
                      </Box>
                    ) : (
                      <Typography
                        variant="body2"
                        sx={{
                          display: "-webkit-box",
                          WebkitLineClamp: "1",
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                        }}
                      >
                        CLIENTS TYPES:{" "}
                        {clientTypes
                          ?.map((item) => item?.name)
                          .join(", ")}
                      </Typography>
                    )}
                  </Box>
                )}

                {!isMobileView &&
                  Boolean(
                    userData?.treatment_program_info
                      ?.treatment_type?.name
                  ) &&
                  isTreatment && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 0.5,
                        mb: 0.5,
                      }}
                    >
                      <Box color="text.secondary" display="flex">
                        <UserAddIcon width={14} height={14} />
                      </Box>
                      <Typography
                        variant="body2"
                        sx={{
                          display: "-webkit-box",
                          WebkitLineClamp: "1",
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                        }}
                      >
                        Treatment Type:{" "}
                        {
                          userData?.treatment_program_info
                            ?.treatment_type?.name
                        }
                      </Typography>
                    </Box>
                  )}

                {age_served?.length > 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 0.5,
                    }}
                  >
                    {!isMobileView ? (
                      <Box color="text.secondary" display="flex">
                        <DeskCustomerIcon />
                      </Box>
                    ) : null}
                    {isMobileView ? (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          mt: 1.5,
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            display: "-webkit-box",
                            WebkitLineClamp: "1",
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            color: "#61676B",
                            fontSize: "10px",
                            fontWeight: 500,
                          }}
                        >
                          AGES SERVED:
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            display: "-webkit-box",
                            WebkitLineClamp: "1",
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            fontSize: "14px",
                          }}
                        >
                          {age_served
                            ?.split(",")
                            .filter((item) => Boolean(item))
                            ?.join(", ")}
                        </Typography>
                      </Box>
                    ) : (
                      <Typography
                        variant="body2"
                        sx={{
                          display: "-webkit-box",
                          WebkitLineClamp: "1",
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                        }}
                      >
                        AGES SERVED:{" "}
                        {age_served
                          ?.split(",")
                          .filter((item) => Boolean(item))
                          ?.join(", ")}
                      </Typography>
                    )}
                  </Box>
                )}
              </>
            ) : userData.info?.about_me ? (
              <Box
                sx={{
                  display: "flex",
                  gap: 0.5,
                }}
              >
                <Box color="border.dark" display="flex" mt={0.5}>
                  <StickyNoteIcon />
                </Box>
                <Typography
                  variant="body2"
                  sx={{
                    display: "-webkit-box",
                    WebkitLineClamp: "2",
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                  }}
                >
                  {userData.info?.about_me}
                </Typography>
              </Box>
            ) : (
              <Box minHeight={44} />
            )}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row", md: "column" },
          justifyContent: { xs: "center", sm: "space-between" },
          gap: 2,
          minWidth: 140,
          maxWidth: { md: 140 },
          flexBasis: { md: 140 },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          {showRemoveIcon ? <IconButton
            onClick={() => {
              deleteConnection(userData.id, userData.account_type)
                .then((res) => {
                  sendAlert(res.data.message, "success");
                  clearSearch();
                })
                .catch((error) => {
                  sendAlert(error.response?.data?.message, "error");
                });
            }}
            sx={{
              maxWidth: { sm: 150, md: "none" },
              color: "primary.main",
              p: 1.25,
            }}
          >
            <MinusUserIcon
              color="red"
              style={{ color: "red !important" }}
              width={16}
              height={16}
            />
          </IconButton> : null}
          {isMobileView || isSameUser() ? null : !data.is_connected &&
            !Boolean(data?.connected_user) ? (
            <IconButton
              onClick={connect}
              disabled={loading}
              sx={{
                maxWidth: { sm: 150, md: "none" },
                color: "primary.main",
                p: 1.25,
              }}
            >
              <AddUserIcon width={16} height={16} />
            </IconButton>
          ) : (
            <IconButton
              sx={{
                maxWidth: { sm: 150, md: "none" },
                color: "primary.main",
                p: 1.25,
              }}
              onClick={favouriteUser}
            >
              {isLiked ? (
                <HeartFilledIcon width={16} height={16} />
              ) : (
                <HeartIcon width={16} height={16} />
              )}
            </IconButton>
          )}
          {enableShare && isMobileView ? null : (
            <IconButton
              sx={{
                maxWidth: { sm: 150, md: "none" },
                color: "primary.main",
                p: 1.25,
              }}
              onClick={handleShareClick}
            >
              <ShareIcon width={16} height={16} />
            </IconButton>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "8px",
          }}
        >
          {enableReferral && (
            <Button
              variant="contained"
              onClick={handleReferralClick}
              startIcon={<SendIcon />}
              sx={{
                py: 0.75,
                whiteSpace: "nowrap",
                maxWidth: { sm: 140, md: "none" },
                flex: 1,
              }}
            >
              &nbsp;&nbsp;Send Referral
            </Button>
          )}
          {isMobileView &&
            !data.is_connected &&
            !Boolean(data?.connected_user) && (
              <Button
                variant="outlined"
                startIcon={<AddUserIcon />}
                onClick={connect}
                disabled={loading}
                sx={{
                  py: 0.75,
                  whiteSpace: "nowrap",
                  maxWidth: { sm: 140, md: "none" },
                  flex: 1,
                }}
              >
                &nbsp;&nbsp;&nbsp;Add connection
              </Button>
            )}
        </Box>
      </Box>
    </Paper>
  );
};

export default UserCard;
