import React, { useEffect, useState } from 'react'
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import LoadingButton from '@mui/lab/LoadingButton';

import { LOADING, SUCCESS } from '../../constants/common';
import CheckboxListItem from '../CheckboxListItem';
import { useBasicInfo } from '../../contexts/BasicInfo';
import { useClientServed } from '../../contexts/ClientServed';

const ClientsServedDrawer = ({ onClose, open }) => {
  const {
    updateClientTypesState,
    fetchClientTypes,
    clientTypes,
    fetchClientTypesStatus,
    editClientTypes,
    updateClientTypesStatus,
    fetchUserClientTypes
  } = useClientServed();

  const { fetchInfo, data: info } = useBasicInfo();
  const [error, setError] = useState(false);


  const [selectedClientTypes, setSelectedClientTypes] = useState([]);

  function handleClose() {
    onClose();
  }

  function selectClientTypes(data) {
    setSelectedClientTypes((prevState) => {
      const exists = !!prevState.find((item) => +item === data.id);
      if (exists) {
        return (
          prevState.filter((item) => +item !== data.id)
        )
      }
      return (
        [
          ...prevState,
          data.id
        ]
      )
    })
    setError(!selectClientTypes?.length)
  }

  function saveClientTypes() {
    if (!selectedClientTypes?.length) {
      setError(true);
      return;
    }
    editClientTypes({
      populations: selectedClientTypes
    })
  }

  function disabled() {
    return selectedClientTypes.length < 1
  }

  useEffect(() => {
    if (open) {
      fetchClientTypes();
    }
  }, [open])


  useEffect(() => {
    if (updateClientTypesStatus === SUCCESS) {
      updateClientTypesState('updateClientTypesStatus', '');
      fetchUserClientTypes();
      if (info?.client_types?.length < 1)
        fetchInfo();
      handleClose();
    }
  }, [updateClientTypesStatus, handleClose])

  useEffect(() => {
    setSelectedClientTypes(clientTypes.reduce((acc, item) => {
      if (item.selected) {
        return [...acc, item.id.toString()]
      }
      return acc;
    }, []))
    setError(false);
  }, [clientTypes])

  return (
    <Drawer
      open={open}
      onClose={handleClose}
      anchor='right'
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          pt: 8
        }}
      >
        <Box pb={3} px={3}>
          <Typography variant='h4' mb={1}>Clients Served</Typography>
          <Typography variant='body1' mb={1}>Select the types of clients you work with</Typography>
          <>
            <Divider sx={{ mb: 1 }} />
            <Box>
              <Typography variant='body2' fontWeight='bold' mb={2}>
                Client types
              </Typography>
              <Grid container spacing={2.5} mb={2}>
                {fetchClientTypesStatus === LOADING ? (
                  <>
                    {[...Array(6)].map((_, index) => (
                      <Grid item xs={6} key={index}>
                        <Skeleton animation="wave" />
                      </Grid>
                    ))}
                  </>
                ) : (
                  clientTypes.map((item) => (
                    <CheckboxListItem
                      key={item.id}
                      data={item}
                      selectItem={selectClientTypes}
                      selectedItems={selectedClientTypes}
                    />
                  ))
                )}
              </Grid>
            </Box>
          </>
        </Box>

        <Box
          sx={{
            boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.08)',
            px: 1,
            pt: 3,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            position: 'sticky',
            width: '100%',
            backgroundColor: 'background.paper',
            bottom: 0,
            zIndex: 9
          }}
        >
          <Button
            variant='outlined'
            color='secondary'
            sx={{
              color: 'primary.main'
            }}
            size='large'
            fullWidth
            onClick={handleClose}
          >
            Cancel
          </Button>
          <LoadingButton
            variant='contained'
            size='large'
            fullWidth
            onClick={saveClientTypes}
            loading={[updateClientTypesStatus].includes(LOADING)}
          >
            Save
          </LoadingButton>
        </Box>
        {error && (
          <Typography variant='caption' color='error' fontSize={14} sx={{ p: 2 }}>
            Please select one or more client type.
          </Typography>
        )}
      </Box>
    </Drawer>
  )
}

export default ClientsServedDrawer;