import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { currentPracticeSchema } from "../../../validations/validations";
import { ReactComponent as RadioButtonIcon } from "../../../assets/Icons/radio-button.svg";
import { ReactComponent as RadioButtonMarkedIcon } from "../../../assets/Icons/radio-button-marked.svg";
import { ReactComponent as ShieldExclamation } from "../../../assets/Icons/shield-exclamation.svg";
import { useOnboarding } from "../../../contexts/onboarding";
import "./style.css";
import { trackClickEvent } from "../../../mixpanel";

const CurrentPractice = () => {
  const { next, updateOnboardingState, onboardingState } = useOnboarding();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(currentPracticeSchema),
    defaultValues: {
      current_status: [],
    },
  });

  useEffect(() => {
    if (onboardingState.current_status) {
      setValue('current_status', onboardingState.current_status);
    }
  }, [])

  const onSubmit = (data) => {
    updateOnboardingState({ current_status: data.current_status });
    trackClickEvent(
      'Onboarding User',
      { page: 'Register', component: 'U-Step 4 - Current Practice', button: 'Continue' }
    );
    next();
  };

  return (
    <Container maxWidth="md">
      <form onSubmit={handleSubmit(onSubmit)} className="practiceForm">
        <Typography variant="h5" mb={3}>
          Which of the following best describes your current practice?
        </Typography>

        <Controller
          name="current_status"
          control={control}
          render={({ field }) => (
            <Button
              {...field}
              variant="outlined"
              color="secondary"
              fullWidth
              sx={{
                color: "text.primary",
                mb: 3,
                gap: "6px",
                justifyContent: "flex-start",
                minHeight: 65,
                fontSize: 16,
                fontWeight: 400,
              }}
              startIcon={
                field.value === "accepting_new_clients" ? (
                  <RadioButtonMarkedIcon />
                ) : (
                  <RadioButtonIcon />
                )
              }
              onClick={() => field.onChange("accepting_new_clients")}
            >
              Accepting new clients
            </Button>
          )}
        />

        <Controller
          name="current_status"
          control={control}
          render={({ field }) => (
            <Button
              {...field}
              variant="outlined"
              color="secondary"
              fullWidth
              sx={{
                color: "text.primary",
                mb: 3,
                gap: "6px",
                justifyContent: "flex-start",
                minHeight: 65,
                fontSize: 16,
                fontWeight: 400,
              }}
              startIcon={
                field.value === "not_accepting_clients" ? (
                  <RadioButtonMarkedIcon />
                ) : (
                  <RadioButtonIcon />
                )
              }
              onClick={() => field.onChange("not_accepting_clients")}
            >
              Not accepting new clients
            </Button>
          )}
        />

        <Controller
          name="current_status"
          control={control}
          render={({ field }) => (
            <Button
              {...field}
              variant="outlined"
              color="secondary"
              fullWidth
              sx={{
                color: "text.primary",
                mb: 3,
                gap: "6px",
                justifyContent: "flex-start",
                minHeight: 65,
                fontSize: 16,
                fontWeight: 400,
              }}
              startIcon={
                field.value === "somewhere_in_between" ? (
                  <RadioButtonMarkedIcon />
                ) : (
                  <RadioButtonIcon />
                )
              }
              onClick={() => field.onChange("somewhere_in_between")}
            >
              Somewhere in between
            </Button>
          )}
        />

        {errors.current_status && (
          <Alert
            severity="error"
            sx={{
              backgroundColor: "#FAD4D4",
              borderRadius: 1,
              border: "1px solid #EE6363",
              mb: 1.5,
              mt: -1.5,
              py: 0.5,
              px: 3,
              alignItems: "center",
              color: "text.primary",
            }}
            icon={<ShieldExclamation />}
          >
            {errors.current_status.message}
          </Alert>
        )}
        {errors.current_status && (
          <Typography variant="caption" color="error">
            Please select current practices.
          </Typography>
        )}

        <Box pt={5} textAlign="center">
          <Button
            className="btnContinue"
            variant="contained"
            sx={{ minWidth: 212 }}
            type="submit"
          >
            Continue
          </Button>
        </Box>
      </form>
    </Container>
  );
};

export default CurrentPractice;
