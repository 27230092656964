import React, { useEffect, useState } from 'react'
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSpecialties } from '../../contexts/specialties';
import { LOADING, SUCCESS } from '../../constants/common';
import CheckboxListItem from '../CheckboxListItem';
import { useBasicInfo } from '../../contexts/BasicInfo';

const SpecialtiesDrawer = ({ onClose, open }) => {
  const { fetchSpecialties, editSpecialties, fetchUserSpecialties, updateSpecialtiesState, specialties } = useSpecialties();
  const { fetchInfo, data: info } = useBasicInfo();
  const [selectedSpecialties, setSelectedSpecialties] = useState([]);

  function handleClose() {
    onClose();
  }

  function selectSpecialty(data) {
    setSelectedSpecialties((prevState) => {
      const exists = !!prevState.find((item) => +item === data.id);
      if (exists) {
        return (
          prevState.filter((item) => +item !== data.id)
        )
      }
      return (
        [
          ...prevState,
          data.id
        ]
      )
    })
  }

  function saveSpecialties() {
    editSpecialties({
      specialties: selectedSpecialties
    })
  }

  useEffect(() => {
    if (open)
      fetchSpecialties();
  }, [open])

  useEffect(() => {
    if (specialties.createStatus === SUCCESS) {
      updateSpecialtiesState('createStatus', '');
      fetchUserSpecialties();
      if (info?.specialties?.length < 1)
        fetchInfo();
      handleClose();
    }
  }, [specialties.createStatus, handleClose])


  useEffect(() => {
    setSelectedSpecialties(specialties.list.reduce((acc, item) => {
      if (item.selected) {
        return [...acc, item.id.toString()]
      }
      return acc;
    }, []))
  }, [specialties.list])

  return (
    <Drawer
      open={open}
      onClose={handleClose}
      anchor='right'
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          pt: 8
        }}
      >
        <Box pb={3} px={3}>
          <Typography variant='h4' mb={1}>Specialties</Typography>
          <Typography variant='body1' mb={1}>Mark the practice areas where you have specific expertise.</Typography>
          <Divider sx={{ mb: 4 }} />
          <Grid container spacing={2.5}>
            {specialties.fetchStatus === LOADING ? (
              <>
                {[...Array(6)].map((_, index) => (
                  <Grid item xs={6} key={index}>
                    <Skeleton animation="wave" />
                  </Grid>
                ))}
              </>
            ) : (
              specialties.list.map((item) => (
                <CheckboxListItem
                  key={item.id}
                  data={item}
                  selectItem={selectSpecialty}
                  selectedItems={selectedSpecialties}
                />
              ))
            )}
          </Grid>
        </Box>

        <Box
          sx={{
            boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.08)',
            px: 1,
            pt: 3,
            pb: 5.625,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            position: 'sticky',
            width: '100%',
            backgroundColor: 'background.paper',
            bottom: 0,
            zIndex: 9
          }}
        >
          <Button
            variant='outlined'
            color='secondary'
            sx={{
              color: 'primary.main'
            }}
            size='large'
            fullWidth
            onClick={handleClose}
          >
            Cancel
          </Button>
          <LoadingButton
            variant='contained'
            size='large'
            fullWidth
            onClick={saveSpecialties}
            loading={specialties.createStatus === LOADING}
          >
            Save
          </LoadingButton>
        </Box>
      </Box>
    </Drawer>
  )
}

export default SpecialtiesDrawer;