import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import { LOADING, SUCCESS } from '../../constants/common';
import { useGeneralProfile } from '../../contexts/Organization';

import { ReactComponent as EditIcon } from '../../assets/Icons/edit-3.svg'
import { ReactComponent as CloseIcon } from '../../assets/Icons/close.svg'
import { ReactComponent as QuestionIcon } from '../../assets/Icons/question.svg';
import { profileFaqSchema } from '../../validations/profileValidations';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm, useWatch } from 'react-hook-form';

const ProfileFaqs = () => {
  const {
    editFacilityFaqs,
    updateFacilityFaqsStatus,
    updateGeneralProfileState,
    facilityInfo,
    fetchFacilityInfo
  } = useGeneralProfile();

  const initalState = {
    question_1: '',
    question_2: '',
    question_3: '',
    question_4: '',
    question_5: '',
  }
  const [isEditMode, setIsEditMode] = useState(false);
  const [state, setState] = useState(initalState);
  const [isInvalid, setIsInvalid] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    trigger,
  } = useForm({
    resolver: zodResolver(profileFaqSchema),
    mode: 'onChange',
    defaultValues: {
      facility_name: facilityInfo.info?.facility_name || '',
      business_number: facilityInfo.info?.business_number || '',
      website_link: facilityInfo.info?.website_link || '',
      facility_type_ids: facilityInfo.info?.facility_type_ids?.map(item => item.id) || []
    },
  });

  const formValues = useWatch({ control });

  useEffect(() => {
    setState(prev => ({ ...prev, ...formValues }));
    trigger();
  }, [formValues]);

  useEffect(() => {
    if (updateFacilityFaqsStatus === SUCCESS) {
      updateGeneralProfileState('updateFacilityFaqsStatus', '')
      setIsEditMode(false)
      setIsInvalid(false);
      fetchFacilityInfo();
    }
  }, [updateFacilityFaqsStatus]);

  useEffect(() => {
    if (facilityInfo) {
      setValue('question_1', facilityInfo?.info?.faq?.question_1 || '',);
      setValue('question_2', facilityInfo?.info?.faq?.question_2 || '',);
      setValue('question_3', facilityInfo?.info?.faq?.question_3 || '',);
      setValue('question_4', facilityInfo?.info?.faq?.question_4 || '',);
      setValue('question_5', facilityInfo?.info?.faq?.question_5 || '',);
    }
  }, [facilityInfo])

  const handleInput = (ev) => {
    setValue(`${ev.target.name}`, ev.target.value);
  }

  const handleOpenEditMode = () => {
    setIsEditMode(true)
  }

  const handleCloseEditMode = () => {
    setIsEditMode(false);
    setValue('question_1', facilityInfo?.info?.faq?.question_1 || '',);
    setValue('question_2', facilityInfo?.info?.faq?.question_2 || '',);
    setValue('question_3', facilityInfo?.info?.faq?.question_3 || '',);
    setValue('question_4', facilityInfo?.info?.faq?.question_4 || '',);
    setValue('question_5', facilityInfo?.info?.faq?.question_5 || '',);
  }

  const onSubmit = () => {
    if (isValid) {
      editFacilityFaqs({ faq: state });
    }
  }


  return (
    <Paper
      sx={{
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        gap: 3
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: 'border.dark'
            }}
          >
            <QuestionIcon width={24} height={24} />
          </Box>
          <Typography variant='h4' ml={1}>FAQs</Typography>
        </Box>
        {isEditMode ? (
          <Box>
            <LoadingButton
              type='submit'
              loading={updateFacilityFaqsStatus === LOADING}
              size='small'
              onClick={handleSubmit(onSubmit)}
            >
              Save
            </LoadingButton>
            <IconButton
              sx={{ p: 0.375 }}
              onClick={handleCloseEditMode}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        ) : (
          <IconButton
            sx={{ p: 0.375 }}
            onClick={handleOpenEditMode}
          >
            <EditIcon />
          </IconButton>
        )}
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant='body1' fontWeight={600} mb={1}>{`What insurance does ${facilityInfo?.info?.facility_name} accept?`}</Typography>
          <Controller
            name='question_1'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant='outlined'
                multiline
                rows={4}
                fullWidth
                error={!!errors.question_1}
                helperText={errors.question_1?.message}
                value={state.question_1}
                onChange={handleInput}
                disabled={!isEditMode}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant='body1' fontWeight={600} mb={1}>{`Where is ${facilityInfo?.info?.facility_name} located?`}</Typography>
          <Controller
            name='question_2'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant='outlined'
                multiline
                rows={4}
                fullWidth
                error={!!errors.question_2}
                helperText={errors.question_2?.message}
                value={state.question_2}
                onChange={handleInput}
                disabled={!isEditMode}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant='body1' fontWeight={600} mb={1}>{`What insurance does ${facilityInfo?.info?.facility_name} accept?`}</Typography>
          <Controller
            name='question_3'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant='outlined'
                multiline
                rows={4}
                fullWidth
                error={!!errors.question_3}
                helperText={errors.question_3?.message}
                value={state.question_3}
                onChange={handleInput}
                disabled={!isEditMode}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant='body1' fontWeight={600} mb={1}>{`How long is treatment?`}</Typography>
          <Controller
            name='question_4'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant='outlined'
                multiline
                rows={4}
                fullWidth
                error={!!errors.question_4}
                helperText={errors.question_4?.message}
                value={state.question_4}
                onChange={handleInput}
                disabled={!isEditMode}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant='body1' fontWeight={600} mb={1}>{`Does ${facilityInfo?.info?.facility_name} offer detox?`}</Typography>
          <Controller
            name='question_5'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant='outlined'
                multiline
                rows={4}
                fullWidth
                error={!!errors.question_5}
                helperText={errors.question_5?.message}
                value={state.question_5}
                onChange={handleInput}
                disabled={!isEditMode}
              />
            )}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default ProfileFaqs