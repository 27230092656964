import React, { useEffect, useState } from 'react'
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import LoadingButton from '@mui/lab/LoadingButton';
import { LOADING, SUCCESS } from '../../../../constants/common';
import { useBasicInfo } from '../../../../contexts/BasicInfo';
import { ReactComponent as ChevronDown } from '../../../../assets/Icons/chevron-down.svg'
import { useCommon } from '../../../../contexts/common';
import useGeolocation from '../../../../hooks/use-GeoLocation';
import { profileAddressValidation } from '../../../../validations/profileValidations';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm, useWatch } from 'react-hook-form';
import PlaceAutoComplete from '../../../PlaceAutoComplete';

const AddressDrawer = ({ onClose, open }) => {
  const { statesList, fetchStatesList } = useCommon();
  const { editAddress, updateInfoState, updateAddressStatus, fetchInfo, data } = useBasicInfo();
  const initialState = {
    address1: '',
    zip_code: '',
    state: '0',
    city: ''
  }
  const [state, setState] = useState(initialState);
  const { location, loading } = useGeolocation(state.zip_code);
  const [countryState, setCountryState] = useState(null);


  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    trigger
  } = useForm({
    resolver: zodResolver(profileAddressValidation),
    mode: "onChange",
    defaultValues: {
      address1: data?.info?.address?.address1 || "",
      city: data?.info?.address?.city || "",
      state: data?.info?.address?.state || 0,
      zip_code: data?.info?.address?.zip_code || "",
    },
  });

  const formValues = useWatch({ control });

  useEffect(() => {
    setState({ ...formValues });
    trigger();
  }, [formValues]);

  useEffect(() => {
    fetchStatesList()
  }, []);

  useEffect(() => {
    if (updateAddressStatus === SUCCESS) {
      updateInfoState('updateAddressStatus', '')
      fetchInfo();
      handleClose();
    }
  }, [updateAddressStatus]);

  useEffect(() => {
    const updateState = async () => {
      if (countryState) {
        const stateId = statesList.find((st) => st.name === countryState)?.id;
        setValue("state", stateId);
        await trigger();
      }
    }
    updateState();
  }, [countryState]);


  useEffect(() => {
    if (data && open) {
      setState({
        address1: data?.info?.address?.address1,
        city: data?.info?.address?.city,
        zip_code: data?.info?.address?.zip_code,
        state: data?.info?.address?.state?.id,
      })
      setValue('address1', data?.info?.address?.address1);
      setValue('city', data?.info?.address?.city);
      setValue('zip_code', data?.info?.address?.zip_code);
      setValue('state', data?.info?.address?.state?.id);
    }
  }, [data, open]);

  useEffect(() => {
    if ((location.latitude && location.longitude) && state.zip_code && open) {
      setState((prevState) => ({
        ...prevState,
        ...location
      }))
    } else {
      setState((prevState) => {
        delete prevState.latitude
        delete prevState.longitude
        return prevState
      })
    }
  }, [location, state.zip_code, open]);

  const handleClose = () => {
    setState(initialState);
    onClose();
  }

  const handleAddressChange = async (address) => {
    const streetAddress = address?.location?.split(",")[0] || '';
    setCountryState(address?.state);
    setValue("address1", streetAddress || '');
    setValue("city", address.city || '');
    setValue("zip_code", address.zipCode || '');
    await trigger();
  };

  const onSubmit = () => {
    if (isValid) {
      editAddress(state);
    }
  }


  return (
    <Drawer
      open={open}
      onClose={handleClose}
      anchor='right'
      sx={{ zIndex: 1000 }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          pt: 8
        }}
      >
        <Box pb={3} px={3} >
          <Typography variant='h4' mb={1}>Address</Typography>
          <Divider sx={{ mb: 4 }} />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <PlaceAutoComplete
                name="address1"
                variant="outlined"
                label="Street address"
                fullWidth
                onChange={handleAddressChange}
                error={!!errors.address1}
                helperText={errors.address1?.message}
                control={control}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="state"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    variant="outlined"
                    label="State"
                    fullWidth
                    error={!!errors.state}
                    helperText={errors.state?.message}
                    SelectProps={{
                      IconComponent: () => (
                        <Box
                          sx={{
                            minWidth: 24,
                            width: 24,
                            height: 24,
                            display: "inline-flex",
                            alignItems: "center",
                            justifyContent: "center",
                            mr: 2,
                            pl: 2,
                          }}
                        >
                          <ChevronDown width={12} height={6} />
                        </Box>
                      ),
                      MenuProps: {
                        PaperProps: {
                          style: {
                            maxHeight: 220,
                            width: 250,
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value={0} disabled>
                      Select a state
                    </MenuItem>
                    {statesList.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="city"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    label="City"
                    fullWidth
                    error={!!errors.city}
                    helperText={errors.city?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="zip_code"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    label="ZIP code"
                    fullWidth
                    error={!!errors.zip_code}
                    helperText={errors.zip_code?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.08)',
            px: 1,
            pt: 3,
            pb: 5.625,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            position: 'sticky',
            width: '100%',
            backgroundColor: 'background.paper',
            bottom: 0,
            zIndex: 9
          }}
        >
          <Button
            variant='outlined'
            color='secondary'
            sx={{
              color: 'primary.main'
            }}
            size='large'
            fullWidth
            onClick={handleClose}
          >
            Cancel
          </Button>
          <LoadingButton
            variant='contained'
            size='large'
            fullWidth
            loading={updateAddressStatus === LOADING}
            disabled={loading}
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </LoadingButton>
        </Box>
      </Box>
    </Drawer>
  )
}

export default AddressDrawer;